<!-- 专题活动 -->
<template>
    <div>
        <PageTop1></PageTop1>
        <PageTop></PageTop>
        <div class="v2-g-w1200">
            <div class="filter_btn_box_item">
                <b-select v-model="csValue" placeholder="请选择" expanded @input="csChange">
                    <option value="">全部厂家</option>
                    <option :value="item.supplierCode" :key="index" v-for="(item, index) in selector">
                        {{ item.supplierName }}
                    </option>
                </b-select>
                <div class="spplierInfo" v-if="currentSupplierData">
                    <img :src="currentSupplierData.icon"></img>
                    <div style="text-align: left">
                        <p>控销厂家：{{currentSupplierData.name}}</p>
                        <p>商品个数：{{pageInfo.total}}</p>
                    </div>
                    <div class="tag-content" @click="dl_modal = true">代理联系方式</div>
                </div>
                <div class="search-box">
                    <div class="search-inp">
                        <input type="text" placeholder="请输入控销厂家或控销产品名称" v-model="searchVal" @keyup.enter="searchData" />
                    </div>
                    <div class="search-img">
                        <span class="iconfont icon-search" @click="searchData">&nbsp;搜索</span>
                    </div>
                </div>
            </div>
            <ProductList :list="list" :pageInfo="pageInfo" v-on:getdata="getDataByPage" :hideToolbar="true" @getGoodsList="getData"></ProductList>
        </div>
        <PageBottom></PageBottom>
        <el-dialog :visible.sync="dl_modal" width="327px" :show-close="false">
            <supplier-ruler-pop :rulerData="currentSupplierData" @popStatus="popStatus"></supplier-ruler-pop>
        </el-dialog>
    </div>
</template>

<script>
import ProductList from "@/components-v2/product/product-list";
import PageTop from "@/components-v2/layout/page-top"
import PageTop1 from "@/components-v2/layout/page-top1"
import PageBottom from "@/components-v2/layout/page-bottom"
import supplierRulerPop from "@/components-v2/supplierRulerPop/index.vue";
import { defaultBuyNum } from '@/utils'
import { kv } from "../../mixin/kv"
import { AccountStorage } from "@services/account";
const accountStorage = new AccountStorage();
export default {
    mixins: [kv],
    data() {
        return {
            list: [],
            pageList:[],
            pcImg: null,
            pageInfo: {
                total: 0,
                pageNum: 1,
                pageSize: 10
            },
            csValue:"",
            defaultLogo: 'https://obs.pujian.com/frontend/app/controlSell/defaultLogo1.png',
            sortType: null,
            selector:[],
            searchVal:"",
            currentSupplierCode:"",
            currentSupplierData:null,
            dl_modal:false
        };
    },
    components: {
        ProductList,
        PageTop,
        PageTop1,
        PageBottom,
        supplierRulerPop
    },
    created() {
        this.getData();
        this.kv_visit("2", this.$route.params.id);
        this.getSupplierList()
    },
    methods: {
        //代理规则弹窗事件
        popStatus(e){
            this.dl_modal = e
        },
        csChange(e){
            this.currentSupplierCode = e
            this.pageInfo.pageNum = 1
            this.getData();
        },
        searchData(){
            this.pageInfo.pageNum = 1
            this.getData();
        },
        //获取厂家列表
        async getSupplierList(){
            const res = await this.$getHttpClient({},this.supplierToken).get("/pjyy-deepexi-supplier-center/ucAccount/getAllSupplier");
            if (res.data.code == 200) {
                this.selector = res.data.data
            }
        },
        async getData() {
            const url = "/pjyy-deepexi-supplier-center/controlSalesProduct/controlSupplierList";
            const result = await this.$getHttpClient({},this.supplierToken).post(url, {
                page: this.pageInfo.pageNum,
                pageSize: this.pageInfo.pageSize,
                userCode: accountStorage.getCacheUserInfo().account,
                name:this.searchVal,
                supplierCode: this.currentSupplierCode
            });
            if (result.data.code == '200') {
                this.pageList = result.data.data.list
                let arr = []
                for (let i in result.data.data.list){
                    if (result.data.data.list[i].supplierProductList){
                        arr = arr.concat(result.data.data.list[i].supplierProductList)
                    }
                }
                this.list = defaultBuyNum(arr);
                this.pageInfo.total = result.data.data.total;
                if (this.currentSupplierCode){
                    this.currentSupplierData = this.pageList.find(item => item.supplierCode === this.currentSupplierCode)
                    this.currentSupplierData.controlRule = this.currentSupplierData.agencyRules
                    this.currentSupplierData.controlFactoryContacts = this.currentSupplierData.linkman
                    this.currentSupplierData.controlFactoryPhone = this.currentSupplierData.phone
                    this.currentSupplierData.supplierCode = this.currentSupplierData.supplierCode
                }else{
                    this.currentSupplierData = null
                }
                this.$forceUpdate()
            }
        },
        search(code){
            this.queryParam.code=code;
            this.getData();
        },
        getDataByPage(data) {
            this.pageInfo.pageNum = data.pageNum;
            this.getData();
        }
    }


}
</script>
<style scoped lang="less">
.menu {
  background: #F1F1F4;
  height: 34px;
  align-items: center;
  margin:20px 0;
  cursor: pointer;
  .span {
    width: 75px;
    height: 26px;
    background: #FFFFFF;
    border-radius: 13px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    line-height: 26px;
    margin-right: 10px;
    &.sel{
      background-color: #3875F6;
      color:#fff;
    }
  }
}
.filter_btn_box_item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;

  ::v-deep {
    .el-input__inner {
      border-radius: 100px;
    }
  }

  .spplierInfo{
    width: 45%;
    display: flex;
    align-items: center;
    margin-left: 30px;
    img{
      width: 50px;
      height: 50px;
      margin-right: 20px;
    }
    .tag-content{
      width: 104px;
      height: 36px;
      line-height: 36px;
      margin-left: 20px;
      background: #FFFFFF;
      border: 1px solid #3875F6;
      cursor: pointer;
      border-radius: 6px;
      font-size: 12px;
      color: #3875F6;
    }
  }
  .search-box {
    width: 450px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #EDEDED;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;

    &:hover {
      border: 1px solid #3893fd;
      opacity: .8;
    }

    .search-img {
      display: flex;
      width: 60px;
      font-size: .88rem;
      color: #3893fd;

      .icon-search:before {
        font-size: 1.25rem;
      }

      span {
        font-size: .88rem;
        color: #3893fd;
        margin-top: -2px;

      }
    }

    .search-inp {
      width: 280px;


      input {
        width: 380px;
        height: 30px;
        border: none;
        padding: 10px 0 9px 16px;
      }
    }
  }
}
::v-deep .el-dialog__header{
  padding: 0;
}
::v-deep .el-dialog__body{
  padding: 0;
}
</style>
